import React, { useState } from 'react'
import classes from '@src/styles/BookingWidget.module.css'
import { segmentTrack } from '@src/functions/segment'
import ChooseQuoteModal from '../ChooseQuoteModal'

function ComplexJob() {
  const [showQuoteChooseModal, setShowQuoteChooseModal] = useState(false)

  const handleClick = () => {
    segmentTrack("Complex Job Clicked");
    setShowQuoteChooseModal(true)
  }

  return (
    <>
      <div className={classes.containerQuote}>
        <h6 className={classes.quoteTitle}>Do you have a complex job?</h6>
        <button onClick={handleClick} className={classes.secondaryButton}>
          Submit a quote request
        </button>
      </div>
      <ChooseQuoteModal
        open={showQuoteChooseModal}
        onClose={() => setShowQuoteChooseModal(false)}
      />
    </>
  )
}

export default ComplexJob
