/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useCallback, useEffect, useRef } from 'react'
import classes from '@src/styles/dialog.module.css'
import useOnClickOutside from '@src/hooks/useOnClickOutside'
import ClientOnlyPortal from './portal'

type DialogProps = {
  show: boolean
  onHide?: any
  prefix?: string
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'full'
  centered?: boolean
  header?: JSX.Element
  children: any
}

export default function Dialog({
  show = false,
  onHide = null,
  prefix,
  size = 'md',
  centered = true,
  header,
  children,
}: DialogProps) {
  const modalRef = useRef<any>()
  useOnClickOutside(modalRef, () => onHide())
  const closeOnEscapeKeyDown = useCallback(
    (e: any) => {
      if ((e.charCode || e.keyCode) === 27) {
        onHide()
      }
    },
    [onHide],
  )

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown)
    return () => document.body.removeEventListener('keydown', closeOnEscapeKeyDown)
  }, [closeOnEscapeKeyDown])

  const containerClasses = `${classes.react_modal} ${prefix} ${
    show ? classes.show : classes.fade
  } ${centered ? classes.centered : ''} `
  const contentClasses = `${classes.modal_content} ${classes[size]}`

  return (
    <ClientOnlyPortal>
      <div className={containerClasses} onClick={onHide}>
        <div ref={modalRef} className={contentClasses} onClick={(e) => e.stopPropagation()}>
          {header && header}
          {children}
        </div>
      </div>
    </ClientOnlyPortal>
  )
}
