const checkDynamicInsert = async (context: any) => {
  const { market, location, dynamic } = context.query;

  if (!market && !location) {
    return {
      data: {
        valid: false,
        dynamicText: dynamic ?? "",
        parameters: {
          market: "",
          locationId: "",
          dynamic: dynamic ?? "",
        }
      },
    }
  }

  let task = "junk-removal";
  const taskParam = context.params?.[0];

  if (taskParam) {
    if (taskParam === "cardboard_removal") {
      task = "cardboard-removal";
    }
    if (taskParam === "dumpster_rental") {
      task = "dumpster-rental";
    }
  }

  const dynamicException = market?.includes("St_") ? market.replace("St_", "St._") : market;
  const dynamicAddress = dynamicException
    ?.trim()
    .replaceAll(/[^a-zA-Z. ]/g, " ")
    .trim();
  const url = `${process.env.NEXT_PUBLIC_REDIRECT_URL}api/landing/dynamic-insert`;
  const data = {
    market: dynamicAddress ?? "",
    locationId: location ?? "",
    task,
  };

  let validation: any = {
    valid: false,
    dynamicText: dynamic ?? "",
  };

  const props = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(response => {
      validation = {
        ...response,
        dynamicText: dynamic ?? "",
      };
      return {
        data: {
          ...validation,
          parameters: {
            market: market ?? "",
            locationId: location ?? "",
            dynamic: dynamic ?? "",
          }
        },
      }
    })
    .catch(error => {
      // handle error
      // eslint-disable-next-line no-console
      console.error(error);
      return {
        data: {
          ...validation,
          dynamicText: dynamic ?? "",
          parameters: {
            market: market ?? "",
            locationId: location ?? "",
            dynamic: dynamic ?? "",
          }
        },
      }
    });
  return props;
}

export default checkDynamicInsert;