import React from 'react'
import Group1 from '@src/assets/quote_choose_group1.jpg'
import Group2 from '@src/assets/quote_choose_group2.jpg'
import { useRouter } from 'next/router'
import classes from '@src/styles/ChooseQuoteModal.module.css'
import Divider from '@src/components/divider'
import Dialog from '@src/components/dialog'
import { useMediaQuery } from 'react-responsive'
import queryString from 'query-string'
import { segmentTrack } from '@src/functions/segment'
import { getGclid } from '@src/functions/gclid'

type ChooseQuoteModal = {
  onClose: any
  open: boolean
}

const ChooseQuoteModal = ({ onClose, open = false }: ChooseQuoteModal) => {
  const history = useRouter()
  const { keyword, location } = history.query;
  const fullScreen = useMediaQuery({ query: '(max-width:400px)' });

  const handleInstantQuoteClick = () => {
    onClose()
    const gclid = getGclid();
    segmentTrack("Get Instant Quote Clicked");
    history.push(
      `${process.env.NEXT_PUBLIC_REDIRECT_URL}booking?`.concat(
        queryString.stringify({
          redirect: "1",
          gclid,
          keyword,
          location,
        }),
      ),
    )
  }

  const handleCustomQuoteClick = () => {
    onClose();
    const gclid = getGclid();
    segmentTrack('Get 24 Hour Quote Clicked');
    history.push(
      `${process.env.NEXT_PUBLIC_REDIRECT_URL}booking?`.concat(
        queryString.stringify({
          redirect: "1",
          bid: "1",
          gclid,
          keyword,
          location,
        }),
      ),
    )
  }

  return (
    <Dialog show={open} onHide={onClose}>
      <div className={classes.container}>
        <div className={classes.featureContainer}>
          <img src={Group1.src} alt='instantQuoteImage' />
          <div className={classes.contentWrapper}>
            <p className={classes.features}>If your waste includes...</p>
            <Divider />
            <p className={classes.features}>Single items (Desk, Mattress, etc.)</p>
            <Divider />
            <p className={classes.features}>Many of the same items (10 trash-bags)</p>
          </div>
          <div className={classes.buttonContainer}>
            <button
              className={classes.primaryButton}
              color='primary'
              onClick={handleInstantQuoteClick}
            >
              Get instant quote
            </button>
            <p className={classes.info}>
              Get standard pricing per item through our instantly priced booking flow.
            </p>
          </div>
        </div>
        <div className={classes.featureContainer}>
          <img src={Group2.src} alt='customQuoteImage' />
          <div className={classes.contentWrapper}>
            <p className={classes.features}>If your waste includes...</p>
            <Divider />
            <p className={classes.features}>Complex items (a room full of trash)</p>
            <Divider />
            <p className={classes.features}>Custom business needs (warehouse cleanout)</p>
          </div>
          <div className={classes.buttonContainer}>
            <button
              className={classes.primaryButton}
              color='primary'
              onClick={handleCustomQuoteClick}
            >
              {fullScreen ? '24 hour quote' : '24 hour custom quote'}
            </button>
            <p className={classes.info}>
              Describe your job and receive a competitive price quote from our top providers within
              24 hours.
            </p>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default ChooseQuoteModal
