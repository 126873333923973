import { useRef, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

export default function ClientOnlyPortal({
  children,
  selector = '#modal-portal',
}: {
  children: any
  selector?: string
}) {
  const ref = useRef<any>()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    ref.current = document.querySelector(selector)
    setMounted(true)
  }, [selector])

  return mounted ? createPortal(children, ref.current) : null
}
