import React from 'react'

function Divider() {
  return (
    <hr
      style={{
        border: 'none',
        height: 1,
        width: '100%',
        margin: 0,
        flexShrink: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
      }}
    />
  )
}

export default Divider
