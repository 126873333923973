import React, { useRef, useEffect } from "react";

export interface TrustBoxProps {
  style?: string;
  backgroundColor?: string;
  isDark?: boolean;
  styles?: any;
}

export const desktopTrustPilotLandingStyles = {
  display: "block",
  transform: "scale(0.7)",
  opacity: 1,
  marginRight: "auto",
  marginLeft: -58,
  marginTop: 20,
};

/* The mobile styles only applies to the pages with Booking Widget */
export const mobileTrustPilotLandingStyles = {
  transform: "scale(0.7)",
  transformOrigin: "center",
  opacity: 1,
};

function TrustBox({ styles = null, isMobile = false }: any) {
  const ref = useRef(null);

  useEffect(() => {
    const wind: any = window;
    if (wind.Trustpilot) {
      wind.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
    return (
    // <!-- TrustBox widget - Landing Page Widget -->
    <div style={isMobile ? { position: "relative", display: "block", marginLeft: "auto", marginRight: "auto" } : desktopTrustPilotLandingStyles}>
      <div style={styles ?? isMobile ? mobileTrustPilotLandingStyles : undefined}>
        <div
          ref={ref}
          className="trustpilot-widget"
          data-locale="en-US"
          data-template-id="53aa8807dec7e10d38f59f32"
          data-businessunit-id="637d26b40cd313bd3bd304f4"
          data-style-height="150px"
          data-style-width="100%"
          data-theme="dark"
        >
          <a href="https://www.trustpilot.com/review/getdimension.com" target="_blank" rel="noopener noreferrer">
            Trustpilot
          </a>
        </div>
      </div>
    </div>
    // <!-- End TrustBox widget -->
  );
}

export default TrustBox;
